import './App.css';
import React from 'react';
import CalendarPanel from "./Calendar.js"
import Navibar from "./Navbar.js"
import InputField from "./InputField.js"
import StartCalculate from "./Calculate.js"
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div>
      <Navibar />
      <div className="App">
        <header className="App-header">
        <InputField />
        <CalendarPanel />
        <StartCalculate />
      </header>
      </div>
    </div>
    </ThemeProvider>
  );
}

export default App;

const theme = createTheme({
  components: {
    // CTRL + SPACE to find the component you would like to override.
    // For most of them you will need to adjust just the root...
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#edeeef',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#7382a5',
            },
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          inputText: {
            color: 'rgba(0,0,0,0.87)',
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '28px',
            textAlign: 'center',
        },
          '& label': {
            color: '#edeeef',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#7382a5',
            },
          },
        },
      },
    },

  },

  palette: {
    type: 'dark',
    primary: {
      main: '#edeeef',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: 'rgba(234,234,234,0.87)',
    },
    background: {
      default: '#282C34',
      paper: '#2E3B55',
    },
  },
});