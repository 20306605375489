import React from 'react';
import Calendar from 'react-calendar';
import { useState } from 'react';

export default function CalendarPanel() {
  const [date, setDate] = useState(new Date());
  
  return(
    <div className='calendar-container'>
      <Calendar onChange={setDate} value={date} />
    </div>
  );
}
