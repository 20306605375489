import React from 'react';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

export default function calculate() {
  return(
    <div>
      <Box>
        <Button variant="contained" color="success">Bereken</Button>
      </Box>
    </div>
  )
}