import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

export default function InputField() {
  const [choice, setAge] = React.useState('1');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return(
    <div>
      <Box>
        Bereken de&nbsp;
          <FormControl>
          <InputLabel id="calculate-option-select">Keuze</InputLabel>
          <Select sx={{fontSize:'large'}}
            labelId="calculation"
            id="calculation"
            value={choice}
            label="Keuze"
            onChange={handleChange}
          >
            <MenuItem value={1}>langste vakantie</MenuItem>
            <MenuItem value={2}>grootste aantal weken vakantie</MenuItem>
            <MenuItem value={3}>meeste lange weekenden</MenuItem>
          </Select>
          </FormControl>
          &nbsp;met gebruik van&nbsp;
          <TextField inputProps={{style: {fontSize: 20}}} sx={{width:90}}
            id="outlined-number"
            label="aantal"
            type="number"
          />
          &nbsp;vakantiedagen
      </Box>
    </div> );
}
