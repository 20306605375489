import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Navbar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: '#2e3b55' }}>
        <Toolbar>
          <Box component="img" sx={{ height: 50, m: 0.5 }} alt="Your logo." src="android-chrome-512x512.png"/>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: '#edeeef'}}>
            Weinigwerken
          </Typography>
          <Button>About</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
 